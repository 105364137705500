<script setup lang="ts">
</script>

<template>
  <div data-layout="auth/auth" class="bg-forgd-bgd-500 min-h-screen font-normal text-forgd-primary-900 pt-4 bg-pattern">
    <div class="max-w-forgd mx-auto px-6 flex flex-col items-center justify-center py-14">
      <img src="/images/logo-forgd.svg" alt="Forgd" class="w-60">
      <slot />
      <div class="mt-2 text-sm text-center">
        © 2024 Forgd. All rights reserved. <a href="/terms-of-use/" class="text-forgd-primary-300" target="_blank">Terms & Conditions</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .bg-pattern {
    background-image: url('/patterns/prelogin.svg');
  }
</style>
